import React, { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { THEMES } from 'src/submodules/commons-misc/constant';
import { getStoredSettings, storeSettings } from 'src/utils/settings';
import configService from 'src/services/configService';
import { getTimeZoneList, saveOffset } from 'src/utils/common';
import { createTimeZoneList } from 'src/submodules/ui-components/tz';

const SettingsContext = createContext();

export function SettingsProvider({ settings, children }) {
	const defaultSettings = {
		direction: 'ltr',
		responsiveFontSizes: true,
		theme: THEMES.POST.name,
		language: localStorage.getItem('i18nextLng'),
		timezone:
			settings && settings?.timezone ? settings.timezone : checkTimezone(),
		fleet: null
	};

	const [currentSettings, setCurrentSettings] = useState(
		settings || getStoredSettings() || defaultSettings
	);

	const handleSaveSettings = (updatedSettings = {}) => {
		const mergedSettings = _.merge({}, currentSettings, updatedSettings);

		setCurrentSettings(mergedSettings);
		storeSettings(mergedSettings);
	};

	function checkTimezone() {
		var timeZoneList = getTimeZoneList();
		if (!timeZoneList) {
			timeZoneList = createTimeZoneList();
		}
		var timezoneString = Intl.DateTimeFormat().resolvedOptions().timeZone;
		var timesSplit = timezoneString.split('/');
		var timezoneByBrowser = timeZoneList.filter(t =>
			t.label.includes(timesSplit[1])
		);
		saveOffset(timezoneByBrowser[0]);
		return timezoneByBrowser[0]?.id;
	}

	useEffect(() => {
		document.dir = currentSettings.direction;
	}, [currentSettings]);

	return (
		<SettingsContext.Provider
			value={{
				settings: currentSettings,
				saveSettings: handleSaveSettings
			}}
		>
			{children}
		</SettingsContext.Provider>
	);
}

SettingsProvider.propTypes = {
	children: PropTypes.node.isRequired,
	settings: PropTypes.object
};

export const SettingsConsumer = SettingsContext.Consumer;

export default SettingsContext;
